import React, { FunctionComponent, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import ReactPDFChart from "react-pdf-charts";
import { Bar, BarChart, CartesianGrid, Cell, Dot, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // backgroundColor: '#E4E4E4',
    padding: 10,
    fontSize: '12px'
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
    paddingBottom: 8,
  },
  bordered: {
    borderTop: '1px solid #EEE',
  },
  header: {
    borderTop: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  bold: {
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  title: {
    fontSize: '16px'
  },
  col4: {
    width: '33.333%',
  },
  col3: {
    width: '25%',
  },
  col6: {
    width: '50%',
  },
  col12: {
    width: '100%',
  },
});

// Create Document Component
type Props = {
  locData?: {
    id: number|undefined;
    name: {[k: string]: string};
    user_in_ids: number[];
    user_ex_ids: number[];
    user_in_count: number;
    user_ex_count: number;
    questions: {
      [s:string]: {
        internal: {
          detractors: number;
          promotors: number;
          score: number;
        },
        external: {
          detractors: number;
          promotors: number;
          score: number;
        },
        weight: number;
      }
    },
    totals: {
      [s:string]: {
        internal: number;
        external: number;
      }
    },
    nps_in: {
      detractors: number;
      promotors: number;
      score: number;
    },
    nps_ex: {
      detractors: number;
      promotors: number;
      score: number;
    }
  };
};

export const MyDocument: FunctionComponent<Props> = ({locData}) => (
  <Document>
    <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.center, styles.title]}>
          <Text style={styles.col12}>
            {locData && locData.name.nl}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.col12}>
            Automatisch rapport. Als er geen volgende pagina's zijn, dan is er een data-fout. Contacteer IT indien dit voorkomt.
          </Text>
        </View>
      </View>
    </Page>

    {!!locData && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header, styles.bordered]}>
          <Text style={styles.col3}>
            {locData.name.nl}
          </Text>
          <Text style={styles.col3}>
            Ouders
          </Text>
          <Text style={styles.col3}>
            Personeel
          </Text>
          <Text style={styles.col3}>
            Gewicht
          </Text>
        </View>

        {
          [
            {dimName: "Dimensie 1", dimKey: 'dim1', questions: ['q11', 'q12', 'q13']},
            {dimName: "Dimensie 2", dimKey: 'dim2', questions: ['q21', 'q22', 'q23']},
            {dimName: "Dimensie 3", dimKey: 'dim3', questions: ['q31', 'q32', 'q33', 'q34']},
            {dimName: "Dimensie 4", dimKey: 'dim4', questions: ['q41', 'q42']},
            {dimName: "Dimensie 5", dimKey: 'dim5', questions: ['q51', 'q52', 'q53']},
            {dimName: "Dimensie 6", dimKey: 'dim6', questions: ['q61', 'q62', 'q63']}
          ].map(row => {
            let dimName = row.dimName;
            let dimKey = row.dimKey;
            let questions = row.questions;

            return <>
              {questions.map(q => <>
                {!!locData.questions[q] && <View style={[styles.row, styles.bordered]}>
                  <Text style={styles.col3}>
                    {q.toUpperCase()}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].external.score}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].internal.score}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].weight}
                  </Text>
                </View>}
              </>)}
              {!!locData.totals[dimKey] && <View style={[styles.row, styles.bordered, styles.header]}>
                <Text style={styles.col3}>
                  {dimName}
                </Text>
                <Text style={styles.col3}>
                  {locData.totals[dimKey].external}
                </Text>
                <Text style={styles.col3}>
                  {locData.totals[dimKey].internal}
                </Text>
                <Text style={styles.col3}></Text>
              </View>}
            </>;
          })
        }
        <View style={[styles.row, styles.bordered, styles.header]}>
          <Text style={styles.col3}>
            NPS
          </Text>
          <Text style={styles.col3}>
            {locData.nps_ex.score * 100}%
          </Text>
          <Text style={styles.col3}>
            {locData.nps_in.score * 100}%
          </Text>
          <Text style={styles.col3}></Text>
        </View>
      </View>
    </Page>}



    {!!locData && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        {[
          {"Dimensie 1": 'dim1', "Dimensie 2": 'dim2'},
          {"Dimensie 3": 'dim3', "Dimensie 4": 'dim4'},
          {"Dimensie 5": 'dim5', "Dimensie 6": 'dim6'}
        ].map(dimData => <View style={[styles.row]}>
          {Object.keys(dimData).map(dimName => <View style={[styles.col6]}>
            <Text style={[styles.bold, styles.center]}>{dimName}</Text>
            {/* @ts-ignore */}
            {locData.totals[dimData[dimName]] && <ReactPDFChart>
              <BarChart data={[
                {
                  name: "Personeel",
                  // @ts-ignore
                  score: [0, locData.totals[dimData[dimName]].internal * 2, 0],
                },
                {
                  name: "Ouders",
                  // @ts-ignore
                  score: [0, locData.totals[dimData[dimName]].external * 2, 0],
                }
              ]} height={150} width={240} layout="vertical">
                <XAxis type="number" domain={[0, 10]} />
                <YAxis dataKey="name" type="category" />
                <Bar dataKey="score" isAnimationActive={false}>
                  {[
                    {
                      name: "Personeel",
                      // @ts-ignore
                      score: [0, locData.totals[dimData[dimName]].internal * 2, 0],
                    },
                    {
                      name: "Ouders",
                      // @ts-ignore
                      score: [0, locData.totals[dimData[dimName]].external * 2, 0],
                    }
                  ].map(bar => <Cell fill={(bar.score[1] > 5 ? '#089c19' : '#eb4034')} />)}
                </Bar>
                <CartesianGrid strokeDasharray="3 3" />
              </BarChart>
            </ReactPDFChart>}
          </View>)}
        </View>)}
      </View>
    </Page>}

    {!!locData && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={styles.col12}>
            <ReactPDFChart>
              <ScatterChart height={300} width={550}>
                <CartesianGrid />
                <ReferenceArea x1={0} x2={5} y1={5} y2={0} fill="red" fillOpacity={0.3} />
                <ReferenceArea x1={5} x2={10} y1={5} y2={10} fill="green" fillOpacity={0.3} />
                <ReferenceArea x1={0} x2={5} y1={10} y2={5} fill="orange" fillOpacity={0.2} />
                <ReferenceArea x1={5} x2={10} y1={5} y2={0} fill="orange" fillOpacity={0.2} />

                <XAxis type="number" dataKey="external" label="&nbsp;     &nbsp;      &nbsp;     &nbsp;       &nbsp;   &nbsp;       &nbsp;      &nbsp;      Ouders" domain={[0, 10]} />
                <YAxis type="number" dataKey="internal" label="Pers.  &nbsp; &nbsp;   &nbsp;" domain={[0, 10]} />

                <Scatter isAnimationActive={false} data={['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => {return !!locData.totals[dimKey] ? {
                  name: `Dimensie ${i+1}`,
                  internal: locData.totals[dimKey].internal * 2,
                  external: locData.totals[dimKey].external * 2
                } : {name: '', internal: '', external: ''};})} fill="#8884d8" shape={(props:any) => {
                  const {cx, cy, fill, name} = props;
                  return (
                    <g>
                      <Dot cx={cx} cy={cy} r={5} fill={fill} />
                      <g transform={`translate(${cx},${cy})`}>
                        <text x={10} y={0} dy={4} textAnchor="left" fontSize={10}>{name}</text>
                      </g>
                    </g>
                   );
                }} />

                <ReferenceLine y={0} stroke="#000000" />
                <ReferenceLine x={0} stroke="#000000" />
              </ScatterChart>
            </ReactPDFChart>
          </View>
        </View>
      </View>
    </Page>}
  </Document>
);
export default MyDocument;