import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import ReactDOM from 'react-dom';
import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import { Bar, BarChart, CartesianGrid, Dot, Line, LineChart, ReferenceArea, ReferenceLine, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { PieChart, Cell, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DivergingStackedBar,
  DivStaBarBetter,
  Page,
  StyledButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import MyDocument from '../pdf/CustomReportBc';

import { ReportVersion } from '../../models/ReportVersion';

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type Props = {};

export const AdminReportVersionVisualizer: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  // const theme = useTheme();
  const {t} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [reportVersion, setReportVersion] = useState<ReportVersion>();
  const [loader, setLoader] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string | undefined>(params.id);
  const [activeLocation, setActiveLocation] = useState<string>('');
  const [locData, setLocData] = useState<{
    id: number|undefined;
    name: {[k: string]: string};
    user_in_ids: number[];
    user_ex_ids: number[];
    user_in_count: number;
    user_ex_count: number;
    questions: {
      [s:string]: {
        internal: {
          detractors: number;
          promotors: number;
          score: number;
        },
        external: {
          detractors: number;
          promotors: number;
          score: number;
        },
        weight: number;
      }
    },
    totals: {
      [s:string]: {
        internal: number;
        external: number;
      }
    },
    nps_in: {
      detractors: number;
      promotors: number;
      score: number;
    },
    nps_ex: {
      detractors: number;
      promotors: number;
      score: number;
    }
  }>();
  const [instance, updateInstance] = usePDF({
    document: (<MyDocument
      locData={locData}
    />),
  });

  const fetchCalculation = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      setLoader(true);
      
      fetch_one<ReportVersion>('/nl/v3/objects/fetch_all', {
        object: 'report_version',
        fields: ['id', 'extra_data', 'name'],
        id: projectId
      }, (ob) => {
        setReportVersion(ob);
        setLoader(false);
      }, () => {
        // setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  

  useEffect(() => {
    fetchCalculation();
  }, []);
  useEffect(() => {
    let dimension = Object.values((reportVersion?.extra_data?.results?.dimensions || {})).filter(fff => (fff.id?.toString() || '0') === activeLocation)[0];

    if (!!dimension) {
      // console.log(dimension);
      setLocData(dimension);
    }
  }, [activeLocation]);
  useEffect( () => {
    // console.log(locData);
    updateInstance(<MyDocument
      locData={locData}
    />)
  }, [locData])

  return (
    <Page sx={{}} title={t("objects.report_version.visualizer.title")}>
      <Grid container spacing="8">
        {/* <Grid item xs={12}>
          {!!reportVersion?.extra_data?.results && JSON.stringify(reportVersion?.extra_data?.results)}
        </Grid> */}

        <Grid item xs={6}>
          <StyledSelect
            label="Location"
            value={activeLocation}
            id='location'
            onChange={(v) => {
              setActiveLocation(v);
            }}
            list={[
              {id: '', name: "--- ---"},
              ...(Object.values((reportVersion?.extra_data?.results?.dimensions || {})).map(dimension => {return {id: dimension.id?.toString() || '0', name: dimension.name.en || ''}}))
            ]}
          />
        </Grid>
        {!!locData && <Grid item xs={6}>
          {/* <StyledButton
            label="Download report"
            id="download"
            onClick={() => {
              ReactDOM.render(<PDFViewer><MyDocument /></PDFViewer>, document.getElementById('root'));
            }}
          /> */}
          {/* <PDFDownloadLink document={<MyDocument
            locData={locData}
          />} fileName="report.pdf">
            {/* @ts-ignore *}
            {({ loading }) => loading ? 'Loading document...' : 'Download now!'}
          </PDFDownloadLink> */}
          {!!instance.url && <a href={instance.url} download="report.pdf">Download</a>}
        </Grid>}

        {!!reportVersion?.extra_data?.results?.dimensions && Object.values(reportVersion?.extra_data?.results.dimensions).map(dimension => <>
          {!!dimension.id && dimension.id.toString() === activeLocation && <>
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.name.en}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Ouders</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Personeel</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Gewicht</Typography>
            </Grid>


            {['q11', 'q12', 'q13'].map(q => <>
              <Grid item xs={4}>
                <Typography>{q.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].external.score}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].internal.score}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{dimension.questions[q].weight}%</Typography>
              </Grid>
            </>)}
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>Dimensie 1</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim1.external}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim1.internal}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>


            {['q21', 'q22', 'q23'].map(q => <>
              <Grid item xs={4}>
                <Typography>{q.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].external.score}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].internal.score}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{dimension.questions[q].weight}%</Typography>
              </Grid>
            </>)}
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>Dimensie 2</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim2.external}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim2.internal}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>


            {['q31', 'q32', 'q33', 'q34'].map(q => <>
              <Grid item xs={4}>
                <Typography>{q.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].external.score}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].internal.score}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{dimension.questions[q].weight}%</Typography>
              </Grid>
            </>)}
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>Dimensie 3</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim3.external}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim3.internal}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>


            {['q41', 'q42'].map(q => <>
              <Grid item xs={4}>
                <Typography>{q.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].external.score}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].internal.score}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{dimension.questions[q].weight}%</Typography>
              </Grid>
            </>)}
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>Dimensie 4</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim4.external}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim4.internal}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>


            {['q51', 'q52', 'q53'].map(q => <>
              <Grid item xs={4}>
                <Typography>{q.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].external.score}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].internal.score}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{dimension.questions[q].weight}%</Typography>
              </Grid>
            </>)}
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>Dimensie 5</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim5.external}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim5.internal}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>


            {['q61', 'q62', 'q63'].map(q => <>
              <Grid item xs={4}>
                <Typography>{q.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].external.score}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{dimension.questions[q].internal.score}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{dimension.questions[q].weight}%</Typography>
              </Grid>
            </>)}
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>Dimensie 6</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim6.external}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.totals.dim6.internal}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
              <Typography>NPS</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.nps_ex.score * 100}%</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                <Typography>{dimension.nps_in.score * 100}%</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>

            <Grid item xs={12}>&nbsp;</Grid>



            
            {!!locData && ['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => <Grid item xs={4}>
              <Typography sx={{textAlign: 'center'}}>Dimensie {i+1}</Typography>
              {!!locData.totals[dimKey] && <ResponsiveContainer width="100%" height={200}>
                <BarChart data={[
                  {
                    name: "Ouders",
                    score: [0, locData.totals[dimKey].internal * 2],
                  },
                  {
                    name: "Pers.",
                    score: [0, locData.totals[dimKey].external * 2]
                  }
                ]} layout="vertical" margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}>
                  <XAxis type="number" domain={[0, 10]} />
                  <YAxis dataKey="name" type="category" />
                  <Bar dataKey="score">
                    {[
                      {
                        name: "Ouders",
                        score: [0, locData.totals[dimKey].internal * 2],
                      },
                      {
                        name: "Pers.",
                        score: [0, locData.totals[dimKey].external * 2]
                      }
                    ].map(bar => <Cell fill={(bar.score[1] > 5 ? '#089c19' : '#eb4034')} />)}
                  </Bar>
                  <CartesianGrid strokeDasharray="3 3" />
                </BarChart>
              </ResponsiveContainer>}
            </Grid>)}

            <Grid item xs={12}>&nbsp;</Grid>



            
            {!!locData && <Grid item xs={12}>
              <ResponsiveContainer width="100%" height={600}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                  }}
                >
                  <CartesianGrid />
                  <ReferenceArea x1={0} x2={5} y1={5} y2={0} fill="red" fillOpacity={0.3} />
                  <ReferenceArea x1={5} x2={10} y1={5} y2={10} fill="green" fillOpacity={0.3} />
                  <ReferenceArea x1={0} x2={5} y1={10} y2={5} fill="orange" fillOpacity={0.2} />
                  <ReferenceArea x1={5} x2={10} y1={5} y2={0} fill="orange" fillOpacity={0.2} />

                  <XAxis type="number" dataKey="external" label="&nbsp;     &nbsp;      &nbsp;     &nbsp;       &nbsp;   &nbsp;       &nbsp;      &nbsp;      Ouders" domain={[0, 10]} />
                  <YAxis type="number" dataKey="internal" label="Pers.  &nbsp; &nbsp;   &nbsp;" domain={[0, 10]} />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />

                  <Scatter data={['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => {return !!locData.totals[dimKey] ? {
                    name: `Dimensie ${i+1}`,
                    internal: locData.totals[dimKey].internal * 2,
                    external: locData.totals[dimKey].external * 2
                  } : {name: '', internal: '', external: ''};})} fill="#8884d8" shape={(props:any) => {
                    const {cx, cy, fill, name} = props;
                    return (
                      <g>
                        <Dot cx={cx} cy={cy} r={5} fill={fill} />
                        <g transform={`translate(${cx},${cy})`}>
                          <text x={10} y={0} dy={4} textAnchor="left">{name}</text>
                        </g>
                      </g>
                     );
                  }} />

                  <ReferenceLine y={0} stroke="#000000" />
                  <ReferenceLine x={0} stroke="#000000" />
                </ScatterChart>
              </ResponsiveContainer>
            </Grid>}
          </>}
        </>)}
      </Grid>
    </Page>
  );
}
